var gnavMyAccountCardCallback = gnavMyAccountCardCallback || {};

gnavMyAccountCardCallback.myAccountListsAndFavorites = gnavMyAccountCardCallback.myAccountListsAndFavorites || {};

(function ($) {
  gnavMyAccountCardCallback.myAccountListsAndFavorites = function ($html, data, user) {
    var undef;

    if ((site === undef) || (site.addToCart === undef)) {
      return;
    }

    // Make all of the "add to bag" buttons do exactly that
    $html
      .find('.button.add-to-bag')
      .filter('[data-skuid]')
      .once()
      .on('click', function(e) {
        e.preventDefault();

        var loadingId = site.loading.show({
          'target': e.target,
          'matchTarget': ['display', 'width'],
          'css': {
            'text-align': 'center',
            'vertical-align': 'bottom'
          }
        });

        $(document).one('addToCart.success addToCart.failure', function () {
          site.loading.hide(loadingId);
        });

        var skuId = $(this).attr('data-skuid'),
          skuId = skuId.indexOf('SKU') == -1 ? skuId : skuId.replace('SKU', '');

        site.addToCart({
          skuBaseId: skuId
        });

        return false;
      });
  };
})(jQuery);
